// Adapted from https://codesandbox.io/s/instanced-vertex-colors-8fo01

import React, { useEffect, useMemo, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"
import { WobblyMaterial } from "./WobblyMaterial"
import { InstancedBufferAttribute, Object3D } from "three"

const maxInstances = 1000
const tempObject = new Object3D()
let player

const memoArray = (m = 1) =>
  Float32Array.from(new Array(maxInstances * m).fill())

export function InstancedAvatars({ materialConfig, players }) {
  const meshRef = useRef()
  const { nodes } = useGLTF("/avatar.glb")

  const colorArray = useMemo(() => memoArray(3), [])
  const speedArray = useMemo(() => memoArray(), [])
  const ampArray = useMemo(() => memoArray(), [])
  const freqArray = useMemo(() => memoArray(), [])

  useEffect(() => {
    const attributes = [
      ["color", colorArray, 3],
      ["speed", speedArray, 1],
      ["frequency", freqArray, 1],
      ["amplitude", ampArray, 1],
    ]

    attributes.forEach((item) => {
      nodes.tentaghost.geometry.setAttribute(
        item[0],
        new InstancedBufferAttribute(item[1], item[2])
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useFrame(() => {
    for (let i = 0; i < players.length; i++) {
      player = players[i]
      const pos = player.position ?? [0, 0, 0]
      const rot = player.rotation ?? [0, 0, 0]

      tempObject.position.set(...pos)
      tempObject.rotation.set(...rot)
      tempObject.updateMatrix()
      meshRef.current.setMatrixAt(i, tempObject.matrix)

      // Update color
      const [r, g, b] = player.color ?? [0, 0, 0]
      const offset = i * 3
      colorArray[offset] = r
      colorArray[offset + 1] = g
      colorArray[offset + 2] = b

      // Update wobble speed, amp, freq
      speedArray[i] = player.wobbleSpeed ?? 1
      ampArray[i] = player.wobbleAmplitude ?? 1
      freqArray[i] = player.wobbleFrequency ?? 1
    }

    meshRef.current.geometry.attributes.color.needsUpdate = true
    meshRef.current.geometry.attributes.speed.needsUpdate = true
    meshRef.current.geometry.attributes.amplitude.needsUpdate = true
    meshRef.current.geometry.attributes.frequency.needsUpdate = true
    meshRef.current.instanceMatrix.needsUpdate = true
  })

  return (
    <instancedMesh
      ref={meshRef}
      args={[null, null, maxInstances]}
      geometry={nodes.tentaghost.geometry}
      count={players.length}
    >
      <WobblyMaterial materialConfig={materialConfig} />
    </instancedMesh>
  )
}

useGLTF.preload("/avatar.glb")
